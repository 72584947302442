<template>
  <a-card :bordered='false'>
    <a-row class="query-row" type='flex' :gutter="12">
      <a-col style="width:300px">
        <a-input allowClear placeholder='请输入' v-model="queryData.content">
          <a-select slot="addonBefore" v-model="inputType" style="width: 90px">
            <a-select-option value="remark">消息备注</a-select-option>
            <a-select-option value="msgId">消息ID</a-select-option>
          </a-select>
        </a-input>
      </a-col>
      <a-col style="width:200px">
        <span>发送状态：</span>
        <a-select v-model="queryData.status" allowClear placeholder='全部'>
            <a-select-option value="1">已发送</a-select-option>
            <a-select-option value="0">待发送</a-select-option>
        </a-select>
      </a-col>
      <a-button @click="() => {this.pageNum = 1;this.loadTableData()}" type='primary' style="margin-right:12px">查询</a-button>
      <a-button v-auth="'messageCenter:userMsgOperate'" icon="plus" type='primary' @click="editMessage()">添加系统消息</a-button>
    </a-row>

    <a-table :columns="columns" :dataSource="tableData" :pagination="false" 
        :loading="loading"
        :scroll="{x: true}"
        :rowKey="record => record.id"
        size="default">
        <template slot="options" slot-scope="text, record">
          <div class="options-wrapper">
            <a v-if="record.status == 0" v-auth="'messageCenter:userMsgOperate'" @click="editMessage(record)">编辑</a>
            <a v-if="record.status == 0" v-auth="'messageCenter:userMsgUpdateStatus'" @click="updateStatus(1, record.id)">发送</a>
            <a v-if="record.status == 1" v-auth="'messageCenter:userMsgUpdateStatus'" @click="updateStatus(0, record.id)">撤回</a>
            <a v-if="record.status == 0" v-auth="'messageCenter:userMsgUpdateStatus'" @click="updateStatus(-1, record.id)">删除</a>
          </div>
        </template>
    </a-table>
    <a-pagination
         style="display: flex; flex-direction: row; justify-content: flex-end; margin:10px 10px 5px 0"
         size="small"
         v-model="pageNum"
         :total="total"
         :showTotal="total => `共 ${total} 条`"
         showSizeChanger
         showLessItems
         showQuickJumper
         :pageSize.sync="pageSize"
         @change="loadTableData"
         @showSizeChange="() => {this.pageNum = 1; this.loadTableData()}"
    />


      <MessageForm :messageInfo='currentRow' v-model="showMessageFormModal" :loading='confirmLoading' @submitForm="submitForm" />

  </a-card>
</template>

<script>
import MessageForm from './components/messageForm.vue'
import api from '@/api'
export default {
  name: 'SystemMessage',
  components: {
    MessageForm
  },
  data () {
    return {
      inputType: 'remark',
      queryData: {
        status: undefined,
        content: undefined
      },
      loading: false,
      confirmLoading: false,
      showMessageFormModal: false,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      currentRow: {},
      columns: [
        {
          title: '序号',
          align: 'center',
          ellipsis:true,
          customRender: (_, __, index) => {
            return index + 1
          }
        },
        {
          title: '消息ID',
          dataIndex: 'id',
          align: 'center',
          ellipsis:true
        },
        {
          title: '消息备注',
          dataIndex: 'remark',
          align: 'center',
          ellipsis:true
        },
        {
          title: '可见用户',
          dataIndex: 'userDisplayTypeStr',
          align: 'center',
          ellipsis:true
        },
        {
          title: '消息内容',
          dataIndex: 'content',
          align: 'center',
          ellipsis:true,
          customRender: text => {
            return (
              <div class='content-txt'>{text}</div>
            )
          }
        },
        {
          title: '消息图片',
          dataIndex: 'img',
          align: 'center',
          ellipsis:true,
          customRender: (text, record) => {
            return (
              text ? 
              <a onClick={() => this.previewImg(text)}>查看</a>
              : '-'
            )
          }
        },
        {
          title: '跳转链接',
          dataIndex: 'link',
          align: 'center',
          ellipsis:true,
          customRender: text => {
            return (
              <div class='content-txt'>{text}</div>
            )
          }
        },
        {
          title: '发送状态',
          dataIndex: 'statusStr',
          align: 'center',
          ellipsis:true
        },
        {
          title: '发送时间',
          dataIndex: 'sendTimeStr',
          align: 'center',
          ellipsis:true,
          customRender: (text, {status}) => {
            return status === 0 ? '' : text
          }
        },
        {
          title: '操作',
          align: 'center',
          ellipsis:true,
          scopedSlots: { customRender: 'options' }
        },
      ],
      tableData: [],
    }
  },
  created () {
    this.loadTableData()
  },
  methods: {
    loadTableData () {
      this.loading = true
      let {status, content} = this.queryData
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        msgType: 1,
        status,
        [this.inputType]: content
      }
      api.operationManage.userMsgList(params).then(res => {
        if (res.code === '00000') {
          this.tableData = res.data.dataList
          this.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => this.loading = false)
    },
    previewImg (url) {
      this.$viewerApi({
        images: [url]
      })
    },
    editMessage (record) {
      this.currentRow = {...record} || {}
      this.showMessageFormModal = true
    },
    submitForm (form) {
      this.confirmLoading = true
      let {content, link, userDisplayType, status, remark, img, id} = form
      let params = {
        content, link, userDisplayType, status, remark, img, id,
        msgType: 1
      }
      api.operationManage.userMsgOperate(params).then(res => {
        if (res.code === '00000') {
          this.showMessageFormModal = false
          this.$message.success(res.msg)
          this.loadTableData()
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.confirmLoading = false
      })
    },

    /**
     * @name 修改消息状态
     * @param {status}
     * @param {id}
     * @desc status: 0撤回 -1删除 1发送(展示) 
     * @desc id: 消息id
     */
    updateStatus (status, id) {
      let statusText = {
        '0': '撤回',
        '-1': '删除',
        '1': '发送'
      }
      this.$confirm({
        content:`是否确认${statusText[status]}该消息`,
        okText: `确认${statusText[status]}`,
        onOk: () => {
          return new Promise((resolve,reject) => {
            api.operationManage.userMsgUpdateStatus({id, status}).then(res => {
              if (res.code === '00000') {
                this.loadTableData()
                resolve(res.msg)
              } else {
                reject(res.msg)
              }
            })
          }).then((res) => {
            this.$message.success(res)
          }).catch((err) => {
            this.$message.error(err)
          })
        }
      })
    }
  }
}
</script>

<style scoped lang='less'>
.content-txt {
  max-width: 350px;
  min-width: 200px;
  overflow: hidden;
  white-space: pre-wrap;
}

.options-wrapper {
  > a {
    margin-right: 12px;
  }
  > a:last-child {
    margin-right: 0;
  }
}
</style>